.red-critical {
    select {
        border-color: #ff576c !important;
    }

    label:before {
        border-color: #ff576c !important;
        background-color: #ff576c !important;
    }

    label {
        color: #ff576c !important;
    }
}

.orange-critical {
    label {
        color: #ff8157 !important;
    }
}

.green {
    label:before {
        border-color: #9ACD32 !important;
        background-color: #9ACD32 !important;
    }
}

input:disabled + label:before {
    border-color: #6d747a !important;
    background-color: transparent !important;
}

