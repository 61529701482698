.status-indicator {
  display: flex;
  span {
    margin-left: 5px;
    vertical-align: middle;
    font-size: 18px;
  }
  i {
    font-size: 30px;
  }
}