.sidebar{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  border-top: 2px solid $primary;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;
  background: linear-gradient($navbar-color, $background-states-black);

  

  .sidebar-wrapper{
      position: relative;
      height: calc(100vh - 100px);
      overflow: auto;
      width: 260px;
      z-index: 4;
      padding-bottom: 30px;

      nav {
        display: block !important;
      }
  }

  .sidebar-footer {
      padding: 5px;
      display: flex;
      justify-content: center;
      span {
          color: rgb(226, 226, 226);
      }
  }

  .sidebar-background{
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: block;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center center;
  }

  .sidebar-link {
      text-decoration: none !important;
      padding: 0.5rem 1rem;
      border-left: solid transparent 3px;
      display: block !important;
      color: #FFFFFF;
      margin: 0 !important;
      opacity: .86;
      border-radius: 4px;

      &:hover {
        background-color: $hover-backgroud;
        color: $hover-foregroud;
      }
      
      &.active {
        background-color: $active-backgroud;
        color: $active-foregroud;
        border-left: solid $primary 3px !important;
      }
  }

  .logo{
      padding: 10px 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      position: relative;
      z-index: 4;

      p{
          float: left;
          font-size: 20px;
          margin: 10px 10px;
          color: $white;
          line-height: 20px;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }

      a.logo-mini{
          float: left;
          text-align: center;
          width: 30px;
          margin-right: 15px;

          img{
              width: 40px;
              margin-left: -3px;
              display: block;
              margin-top: 2px;
          }
      }

      a.logo-normal{
          display: block;
      }

      .logo-img{
          width: 34px;
          display: inline-block;
          height: 34px;
          margin-left: -2px;
          margin-top: -2px;
          margin-right: 10px;
          border-radius: 30px;
          text-align: center;
      }
  }



  .nav {
      margin-top: 20px;
      display: block !important;

      li{
          > a{
              color: #FFFFFF;
              margin: 5px 15px;
              opacity: .86;
              border-radius: 4px;
          }

          &:hover > a,
          &.open > a,
          &.open > a:focus,
          &.open > a:hover{
              background: rgba(255,255,255,0.13);
              opacity: 1;
          }

          &.active > a{
              color: #FFFFFF;
              opacity: 1;
              background: rgba(255,255,255,0.23);

          }
      }

      p{
          margin: 0;
          line-height: 30px;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
      }

      .caret{
          top: 24px;
          position: absolute;
          right: 15px;
      }

      i{
          font-size: 28px;
          float: left;
          margin-right: 15px;
          line-height: 30px;
          width: 30px;
          text-align: center;
      }
  }
}


.sidebar,
body > .navbar-collapse{
  .logo{
      padding: 10px 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      p{
          float: left;
          font-size: 20px;
          margin: 10px 10px;
          color: $white;
          line-height: 20px;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }

      .simple-text{
          display: block;
          color: $white;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          text-align: center;
          font-size: 20px;
      }
  }

  

  &:after,
  &:before{
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
  }

  &:before{
      opacity: .33;
      background: #1c1f24;
  }

  &:after{
     z-index: 3;
     opacity: 1;
  }

  &[data-image]:after,
  &.has-image:after{
      opacity: .77;
  }

}


