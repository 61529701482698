.image-modal {
    img{
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    display: flex;
    justify-content: center;
    border: 0;

}