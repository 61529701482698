.servers-service {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 2px;
    margin-bottom: 2px;
    min-height: 32px;
    span {
      color: rgb(223, 223, 223);
    }
    .badge {
      min-width: 90px;
    }
    .middle{
        padding-left: 4px;
        justify-content: center;
    }
    .btn-restart{
        margin-left: 30%
    }
  }