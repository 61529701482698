.status-container {
  td{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-top: none;
  }

}

.channel {
  &.danger {
    border-color: rgb(255, 66, 66) !important;
    border-width: 3px !important;
    background-color:#734141;
  }
  .tcp {
    background-color: rgb(84, 170, 84);
    color: rgb(58, 58, 58);
    text-transform: uppercase;
  }
  .udp {
    background-color: rgb(36, 70, 182);
    text-transform: uppercase;
  }
  .centered{
    margin-top: 2%;
  }
}

.channel-number {
  display: inline-block;
  margin-left: 5px;
}

.machine-list {
  position: absolute;
  z-index: 2;
  width: calc(100% - 15px - 0.25rem);
  background: #27293d;
  border: 1px solid #407ef0;
  border-radius: 0.4285rem;
  margin-top: 10px;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.machine-item {
  padding-left: 0.7rem;
  font-size: 0.75rem;
}

.machine-item:hover {
  background: #407ef0;
}

.machine-item button {
  width: 100%;
}

input::placeholder {
    font-weight: normal !important;
}

.polygon-input {
  div.form-group {
    width: 100%;
  }
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #344675 !important;
  cursor: pointer;
}

.rdtPicker thead tr:first-of-type th:hover {
  background: #344675 !important;
}
.rdtCounter .rdtBtn:hover {
  background: #344675 !important;
}

.rdtPicker {
  background: rgb(39, 41, 61) !important;
}
