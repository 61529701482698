.sub-channels {
  border-left: 3px !important;
  background-color: rgba(255, 255, 255, 0.082);

  .sub-channel {
    &.danger {
      border-color: rgb(255, 66, 66) !important;
      border-width: 3px !important;  
      background-color:#734141;
    }
  }
  
}