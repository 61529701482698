.red {
    select {
        border-color: #ff576c !important;
    }
}

.green {
    select {
        border-color: #9ACD32 !important;
    }
}