
body {
  background: linear-gradient($background-black, $background-states-black);
  min-height: 100%;
  color: white;
}

.main-container {
  position: relative;
  min-height: 100vh;
  height: 100%;
  background: linear-gradient($background-black, $background-states-black);
  width: calc(100% - 260px);
  float: right;
  display: block;

  > .content {
    padding: 78px 30px 30px 30px;
    min-height: calc(100vh - 70px);
  }

  > .navbar {
    margin-bottom: 0;
  }

  .header {
    margin-bottom: 50px;
  }
}