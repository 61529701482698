.gchat {
    width: 100%;
    align-items: center;

    span {
        margin-right: 5px;
    }

    p {
        white-space: nowrap;
    }

    .form-group {
        margin: 0 !important;
    }

    .edit-button {
        margin-left: 8px;
    }

    .align-input {
        margin: 27px 5px 0 5px !important;
    }

    .input {
        padding-right: 0.25rem !important;
        padding-left: 15px;
    }

}