.machine-stream {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 4px;
  margin-bottom: 2px;
  span {
    color: rgb(223, 223, 223);
  }
  span:hover {
    color: rgb(255, 255, 255);
  }
  .badge {
    min-width: 80px;
  }
  .badge-forwarder {
    background-color:#24742b;
  }
  a {
    text-decoration: none;
  }
}

