.nav-link {
  background-color: transparent !important;
}

.nav-link.active {
  border: 1px solid #646464 !important;
  border-bottom: 1px solid #27293d !important;
}

.nav-tabs {
  border-bottom: 1px solid #646464 !important;
  margin-bottom: 30px !important;
}

.save-confirmation {
  span {
    padding: 12px;
    font-weight: 600;
    color: rgb(255, 78, 78);
  }
}