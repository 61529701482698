.machine {
  text-decoration: none !important;
  color: rgb(223, 223, 223);
  :hover {
    text-decoration: none;
  }
}

.store-config {
  &.danger {
    .card {
      background-color: rgba(255, 0, 0, 0.096);
      border-color: rgb(255, 78, 78) !important;
      border-width: 3px !important;  
    }
  }
  .delete-confirmation {
    span {
      padding: 12px;
      font-weight: 600;
      color: rgb(255, 78, 78);
    }
  }
  &.primary {
    .card {
      background-color: #213a69;
      border-color: rgb(105, 174, 252) !important;
      border-width: 3px !important;  
    }
  }
  .restart-confirmation {
    span {
      padding: 12px;
      font-weight: 600;
      color: rgb(105, 174, 252);
    }
  }
  
}