.choose-machine {
  font-size: 12px;
}
.align-input {
    margin: 27px 5px 0 5px !important;
}
.fa-angle-up, .fa-angle-down {
  margin-left: 10px;
}
.btn-apply {
  margin-left: 25px !important;
}
.select-all-input {
  padding-left: 15px;
}
.select-all-p {
  padding-left: 5px;
}
.machine-list-bulk {
  width: calc(20% - 5px) !important;
}

h5 {
  margin-bottom: 5px !important;
}

#bulk-actions button {
  font-size: 12px;
  text-decoration: none;
  padding: 5px;
}
#inference-machine-select {
  display: inline-block;
  width: 15%;
  margin-left: 5px;
}
.bulk-input {
  display: inline-block;
  width: 15%;
  margin-left: 5px;
}

.left-25 {
  left: 10px;
}
.darker_default_bg_color {
  background-color: #27293d;
  filter: brightness(0.8);
}
.top-12 {
  top: 3rem;
}
.right-0 {
  right: 0px;
}