.vt-ip {
    display: flex;
    width: 100%;
    align-items: center;

    span {
        margin-right: 5px;
    }

    .form-group {
        margin: 0 !important;
    }

    .edit-button {
        margin-left: 8px;
    }
}