.choice-control {
  .btn {
    padding: 1px 10px 1px 10px;
    background-color:#2c539b !important;
    background-image: none !important;

    &:hover {
      background-color:#3468c9 !important;
    }
    &.active {
      background-color:#407ef0 !important;
      background-image: none !important;
    }
    &:checked {
      background-color: greenyellow;
    }
  }

  .btn-check {
   position: absolute;
    clip: rect(0,0,0,0);
    &.active + .btn {
      background-color:#407ef0 !important;
      background-image: none !important;
      color: white;
    }
    &:checked + .btn {
      background-color:#407ef0 !important;
      background-image: none !important;
      color: white;
    }
  }
}