.video-recorder {
  &.highlight {
    border-color: rgb(61, 175, 95) !important;
    border-width: 3px !important;  
  }
  &.danger {
    border-color: rgb(255, 78, 78) !important;
    border-width: 3px !important;  
    background-color: rgba(255, 0, 0, 0.068);
  }

  .card-body {
    padding-top: 0px;
  }
  .device-type {
    color: rgb(218, 218, 218);
    span {
      font-weight: 500;
      p {
        margin-left: 5px;
        font-size: 22px;
        display: inline;
      }
    }
  }
  .device-channels {
    color: rgb(218, 218, 218);
    font-weight: 400;
    font-size: 1.3rem;
  }
  .device-badge{
    span {
      font-size: 18px;
      font-weight: 400;
      color: rgb(240, 240, 240);
      padding: 10px;
    }
    .left {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .right {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .light {
      background-color: rgb(52, 60, 77);
    }
    .dark {
      background-color: rgb(46, 66, 109);
    }
   
  }
  .delete-confirmation {
    span {
      padding: 12px;
      font-weight: 600;
      color: rgb(255, 78, 78);
    }
  }
}
